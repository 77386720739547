<template>
  <div class="w-input-otp">
    <div class="body-2">
      Nous venons d'envoyer un code de connexion à 4 chiffres sur le numéro
      associé à votre compte. Renseignez ce code de connexion ci-dessous.
    </div>
    <div class="w-input-otp__fields mt-4">
      <v-otp-input
        ref="otpInput"
        input-classes="otp-input"
        separator="-"
        :num-inputs="4"
        :should-auto-focus="true"
        :is-input-num="true"
        @on-complete="handleOnComplete"
      />
    </div>
  </div>
</template>

<script>
import VOtpInput from '@bachdgvn/vue-otp-input'

export default {
  components: {
    VOtpInput,
  },

  methods: {
    handleOnComplete(value) {
      this.$emit('validate', value)
    },
  },
}
</script>

<style lang="scss">
.w-input-otp {
  &__fields {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .otp-input {
      width: 40px;
      height: 40px;
      padding: 5px;
      margin: 0 10px;
      font-size: 20px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.3);
      text-align: center;
      &.error {
        border: 1px solid red !important;
      }
    }
    .otp-input::-webkit-inner-spin-button,
    .otp-input::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
