<template>
  <div class="w-form-new-password">
    <v-form v-if="!missingParams" @submit.prevent="handleSubmit">
      <w-input-password v-model="password" />
      <div class="mt-4 mb-4">
        <v-btn color="primary" type="submit" depressed
          >Réinitialiser le mot de passe</v-btn
        >
      </div>
    </v-form>
    <div v-else>
      Votre lien n'est pas valide pour réinitialiser le mot de passe.
      <nuxt-link to="/password_reset"> Demandez-en un autre ici. </nuxt-link>
    </div>
  </div>
</template>

<script>
import WInputPassword from '~/components/WAuthenticator/password/WInputPassword'

export default {
  components: { WInputPassword },

  data: () => ({
    missingParams: false,
    password: '',
  }),

  mounted() {
    if (!this.$route.query.userId || !this.$route.query.code) {
      this.missingParams = true
    }
  },

  methods: {
    handleSubmit() {
      this.$emit('submit', {
        password: this.password,
        userId: this.$route.query.userId,
        code: this.$route.query.code,
      })
    },
  },
}
</script>
