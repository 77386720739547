<i18n>
{
  "en": {
    "live": "Live",
    "listen": "Listen now",
    "error": "Something went wrong",
    "stop": "Stop",
    "error": "Network error"
  },
  "fr": {
    "live": "Direct",
    "listen": "Écouter",
    "error": "Erreur de lecture",
    "stop": "Arrêter la lecture",
    "error": "Erreur de lecture"
  }
}
</i18n>

<template>
  <v-card
    v-bind="__cardOptions"
    :class="{
      ['w-highlighted--mobile']: $voicer.isMobile,
    }"
    class="w-highlighted"
    flat
    @click="handleSelect"
  >
    <v-img
      :lazy-src="headingImg.lazySrc"
      :src="headingImg.cdnSrc"
      :gradient="$voicer.getImageGradient()"
      :height="$voicer.isMobile ? '100%' : '390'"
      :aspect-ratio="
        $voicer.getThemeConfigProperty('component.card.aspectRatio', 800 / 390)
      "
    >
      <span v-if="isLive" class="live-label red body-2 white--text">{{
        $t('live')
      }}</span>
    </v-img>
    <v-card-title class="w-highlighted__content">
      <div class="pa-4">
        <div
          v-if="$voicer.isDesktopOrTablet"
          class="w-highlighted__content__avatar elevation-2"
        >
          <v-avatar size="84">
            <v-img :src="thumbnailImg.cdnSrc" />
          </v-avatar>
        </div>
        <v-card-title
          primary-title
          :class="{
            'white--text': $voicer.isDarkTheme,
            'black--text': $voicer.isLightTheme,
          }"
        >
          <h2
            class="w-highlighted__content__title font-weight-medium"
            :class="{
              'display-2': $vuetify.breakpoint.mdAndUp,
              headline: $vuetify.breakpoint.smAndDown,
            }"
          >
            {{ title }}
          </h2>
          <h3
            v-if="baseline"
            class="w-highlighted__content__baseline subtitle-2"
            v-text="baseline"
          />
        </v-card-title>
        <div class="w-highlighted__content__infos mt-2">
          <w-card-actions
            :content="content"
            class="w-highlighted__content__actions pa-0 white--text"
          />
          <div class="w-highlighted__content__player">
            <w-button-play
              :content="content"
              :icon="false"
              :props="{
                outlined: true,
                color: 'primary',
                depressed: true,
                large: true,
              }"
            >
              <template v-slot:stopped>
                {{ $t('listen') }} ·&nbsp;
                <span v-if="isLive === false">{{ duration }}</span>
                <span v-else>{{ $t('live') }}</span>
              </template>
              <template v-slot:playing>
                <span v-text="$t('stop')" />
                <v-icon>stop</v-icon>
              </template>
              <template v-slot:error>
                <span v-text="$t('error')" />
                <v-icon>error</v-icon>@
              </template>
              <template v-slot:loading>
                <span v-text="$t('loading')" />
              </template>
            </w-button-play>
          </div>
        </div>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
import VoicyCardMixin from './mixins/common'
import contentInfosMixin from '~/mixins/contentInfos'

export default {
  mixins: [contentInfosMixin, VoicyCardMixin],
}
</script>

<style lang="scss">
.w-highlighted {
  $c: &;
  height: 420px;
  background-color: transparent !important;
  position: relative;

  .live-label {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 8px 12px;
    border-radius: 4px;
  }

  &--mobile {
    height: 310px;
    padding-bottom: 0;
  }

  &__content {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: flex-end;
    text-align: center;

    &__avatar {
      border: 6px white solid;
      display: inline-block;
      width: auto;
      border-radius: 100%;
    }

    &__actions {
      text-align: center;
      justify-content: center;
    }

    &__player {
      margin-top: 20px;
    }

    .v-card__title {
      display: block;
      padding: 0 12%;
    }

    .v-card__text {
      padding: 0;
      padding-bottom: 8px;
    }

    .v-card__actions {
      padding: 0 !important;
      padding-bottom: 16px !important;
    }

    .w-button-play.v-btn {
      margin: 0;
      bottom: auto !important;
    }
  }

  &--mobile {
    padding-bottom: 70px + 40px;

    #{$c}__content {
      justify-content: flex-end;
      padding: 16px;
      max-width: 100%;
      padding-top: 70px;

      .v-card__title {
        padding: 0;

        .title {
          font-size: 24px !important;
          padding-bottom: 12px;
          line-height: 24px !important;
        }
      }
    }
  }
}
</style>
